<script setup lang="ts">
  import {
    CakeIcon,
    CameraIcon,
    HomeModernIcon,
    MusicalNoteIcon,
    PaintBrushIcon,
    UserGroupIcon,
    UserPlusIcon,
  } from '@heroicons/vue/24/outline'
  import { useSwiperCarousel } from '~/composables/useSwiperCarousel'

  const images = [
    '/img/index/carousel/space.webp',
    '/img/index/carousel/photographer.webp',
    '/img/index/carousel/buffet.webp',
    '/img/index/carousel/decoration.webp',
    '/img/index/carousel/dj.webp',
    '/img/index/carousel/garcom.webp',
  ]

  const features = [
    {
      name: 'Espaços',
      description:
        'Únicos e cativantes para todos os eventos estão aqui no EmFesta. Seja qual for o tema, temos o lugar perfeito para tornar sua celebração incrível!',
      icon: HomeModernIcon,
    },
    {
      name: 'Fotografia',
      description:
        'Capture a magia de cada momento com nossos fotógrafos profissionais.',
      icon: CameraIcon,
    },
    {
      name: 'Buffets',
      description:
        'Delicie-se com uma gastronomia inesquecível, escolhida a dedo para seu evento.',
      icon: CakeIcon,
    },
    {
      name: 'Decorações',
      description:
        'Diversas para cada gosto, nossos forncedores são criativos e sempre dispostos a ajudar para que você tenha o tema perfeito para sua festa.',
      icon: PaintBrushIcon,
    },
    {
      name: 'DJs',
      description:
        'Habilidosos a um clique de distância! A EmFesta traz o melhor da música para criar a atmosfera perfeita para sua celebração. Deixe a música tocar!',
      icon: MusicalNoteIcon,
    },
    {
      name: 'Garçons',
      description:
        'Adicione sofisticação ao seu evento com garçons especializados. Conectamos você a profissionais que servem com estilo, eficiência e um sorriso acolhedor!',
      icon: UserGroupIcon,
    },
  ]

  onMounted(() => {
    useSwiperCarousel().swiperElement()
  })
</script>
<template>
  <div class="bg-white pb-6 pt-20 md:pt-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl sm:text-center">
        <h2 class="text-base font-semibold leading-7 text-orange-400">
          Venha festejar conosco
        </h2>
        <p
          class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Tudo que você precisa para sua festa
        </p>
        <p class="mt-6 text-lg leading-8 text-gray-600">
          Descubra espaços deslumbrantes, fornecedores talentosos e serviços
          inovadores para tornar seu evento único.
        </p>
      </div>
    </div>
    <div class="relative overflow-hidden pt-16">
      <div class="mx-auto max-w-2xl px-8 md:max-w-4xl">
        <swiper-container init="false">
          <swiper-slide
            v-for="(image, index) in images"
            :key="index">
            <img
              :src="image"
              :alt="image"
              class="h-96 w-full rounded-xl object-cover shadow-2xl ring-1 ring-gray-900/10 md:h-[44rem]" />
            <div class="swiper-lazy-preloader"></div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
    <div class="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
      <dl
        class="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
        <div
          v-for="feature in features"
          :key="feature.name"
          class="relative pl-9">
          <dt class="inline font-semibold text-gray-900">
            <component
              :is="feature.icon"
              class="absolute left-1 top-1 h-5 w-5 text-orange-400"
              aria-hidden="true" />
            {{ feature.name }}
          </dt>
          {{ ' ' }}
          <dd class="inline">{{ feature.description }}</dd>
        </div>
      </dl>
    </div>
    <div
      class="mx-auto mt-16 w-full max-w-7xl px-6 sm:mt-20 md:mt-24 md:w-1/4 lg:px-8">
      <a
        href="/entrar"
        class="flex items-center justify-center rounded-md bg-orange-400 px-3.5 py-3.5 text-base font-semibold text-white shadow-sm ring-1 ring-orange-400 hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
        <UserPlusIcon
          class="mr-2 h-6 w-6"
          aria-hidden="true" />
        Cadastre-se
      </a>
    </div>
  </div>
</template>
